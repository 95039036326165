import React from "react"
import { useHeaderImage } from "./useHeaderImage"

import Image from "gatsby-image"

const useHeaderData = () => {
  const images = useHeaderImage()

  return {
    mini_kampanie: {
      image: <Image fluid={images.mini_kampanie.childImageSharp.fluid} />,
      section: "Case study",
      title: "Futuro i&nbsp;Viscoplast – mini kampanie.",
    },
    shell: {
      image: <Image fluid={images.mini_kampanie.childImageSharp.fluid} />,
      section: "Case study",
      title: "Mała gastronomia Shell deli2go",
    },
    cersanit: {
      image: <Image fluid={images.mini_kampanie.childImageSharp.fluid} />,
      section: "Case study",
      title: "Cersanit/Opoczno shop-in-shop",
    },
    futuro: {
      image: <Image fluid={images.mini_kampanie.childImageSharp.fluid} />,
      section: "Case study",
      title: <>Futuro i&nbsp;Viscoplast – mini kampanie.</>,
    },
    vox: {
      image: <Image fluid={images.mini_kampanie.childImageSharp.fluid} />,
      section: "Case study",
      title: "System ekspozycyjny VOX",
    },
  }
}

export { useHeaderData }
