import React from 'react';
import {useHeaderData} from 'shared/Hooks/Headers';
import Case from '../../sections/Case';
import {useCaseData} from '../../shared/Hooks/Case';

const CaseViscoplast = () => {
  const data = {
    seo: {
      title: 'Futuro i Viscoplast – mini kampanie.',
    },
    cases: useCaseData().viscoplast,
    header: useHeaderData().futuro,
  };

  return (
    <Case data={data}/>
  );
};

export default CaseViscoplast;
